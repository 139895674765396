import getConfig from 'next/config';
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const API_CMS = publicRuntimeConfig.API_CMS;

const registrationUrl = (origin, registrationToken) =>
	`${origin}/admin/register?registrationToken=${registrationToken}`;

const categoryContentType = 'application::category.category';
const projectContentType = 'application::project.project';
const videoContentType = 'application::video.video';
const pageAboutContentType = 'application::page-about.page-about';
const pageAwardsContentType = 'application::page-award.page-award';
const pageContactContentType = 'application::page-contact.page-contact';
const settingContentType = 'application::setting.setting';

const GET_CATEGORIES = API_CMS + '/categories?_sort=created_at';
const GET_ME = API_CMS + '/users/me';
const GET_PROJECTS = (query) => API_CMS + `/projects${query ? `?${query}` : ''}`;
const GET_VIDEOS = (query) => API_CMS + `/videos${query ? `?${query}` : ''}`;
const GET_PAGE_ABOUT = API_CMS + '/page-about';
const GET_PAGE_AWARDS = API_CMS + '/page-award';
const GET_PAGE_CONTACT = API_CMS + '/page-contact';
const GET_SETTING = API_CMS + '/setting';

// admin
const ADMIN_LOGIN = API_CMS + '/admin/login';
const ADMIN_GET_LIST = (query, contentTypeUID) =>
	API_CMS + `/content-manager/collection-types/${contentTypeUID}${query ? `?${query}` : ''}`;
const ADMIN_GET = (id, contentTypeUID) =>
	API_CMS + `/content-manager/collection-types/${contentTypeUID}/${id}`;
const ADMIN_GENERATE_UID = API_CMS + '/content-manager/uid/generate';
const ADMIN_CREATE = (contentTypeUID) =>
	API_CMS + `/content-manager/collection-types/${contentTypeUID}`;
const PUBLISH = (id, contentTypeUID) =>
	API_CMS + `/content-manager/collection-types/${contentTypeUID}/${id}/actions/publish`;
const UPLOAD = API_CMS + '/upload';

const GET_SINGLE_TYPE = (contentTypeUID) =>
	API_CMS + `/content-manager/single-types/${contentTypeUID}`;

const ADMIN_GET_LIST_USER = (query) => API_CMS + `/admin/users${query ? `?${query}` : ''}`;
const ADMIN_CREATE_USER = API_CMS + `/admin/users`;
const ADMIN_UPDATE_USER = (id) => API_CMS + `/admin/users/${id}`;
const ADMIN_DELETE_USER = API_CMS + `/admin/users/batch-delete`;
const ADMIN_REGISTER = API_CMS + `/admin/register`;
const ADMIN_GET_REGISTRATION_INFO = (registrationToken) =>
	API_CMS + `/admin/registration-info?registrationToken=${registrationToken}`;

const ADMIN_GET_CATEGORIES_OF_PROJECT = (id) =>
	API_CMS + `/content-manager/collection-types/${projectContentType}/${id}`;

export const apiConstants = {
	API_CMS,
	registrationUrl,
	categoryContentType,
	projectContentType,
	videoContentType,
	pageAboutContentType,
	pageAwardsContentType,
	pageContactContentType,
	settingContentType,

	GET_CATEGORIES,
	GET_PROJECTS,
	GET_VIDEOS,
	GET_ME,
	GET_PAGE_ABOUT,
	GET_PAGE_AWARDS,
	GET_PAGE_CONTACT,
	GET_SETTING,

	ADMIN_LOGIN,
	ADMIN_GET_LIST,
	ADMIN_GET,
	ADMIN_GENERATE_UID,
	ADMIN_CREATE,
	PUBLISH,
	UPLOAD,
	GET_SINGLE_TYPE,
	ADMIN_GET_LIST_USER,
	ADMIN_CREATE_USER,
	ADMIN_UPDATE_USER,
	ADMIN_DELETE_USER,
	ADMIN_REGISTER,
	ADMIN_GET_REGISTRATION_INFO,

	ADMIN_GET_CATEGORIES_OF_PROJECT,
};
