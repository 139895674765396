import React, { PureComponent } from 'react';

class IconNext2 extends PureComponent {
   render() {
      return (
         <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M7.00049 2.00098L17.0005 12.001L7.00049 22.001"
               stroke="black"
               strokeWidth="1.5"
            />
         </svg>
      );
   }
}

export default IconNext2;