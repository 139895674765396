import React, { PureComponent } from 'react';
import IconNext from '../assets/icons/IconNext';
import IconPrev from '../assets/icons/IconPrev';
import ProjectPage from './ProjectList/ProjectPage';
class ProjectList extends PureComponent {
   state = {
      pageSize: 15,
      sort: 'updated_at:desc',

      pageList: [],
      currentPage: 0,
      nextPage: null,
      prevPage: null,
   }
   containerRef = null;

   setContainerRef = (r) => {
      this.containerRef = r;
   }

   componentDidMount() {
      this.loadData(this.state.currentPage);
   }

   async componentDidUpdate(prevProps, prevState) {
      if (prevProps.category !== this.props.category) {
         if (this.state.currentPage !== 0) {
            await this.setState({
               pageList: [],
            });
         }
         this.loadData();
      }
   }

   loadData = async (pageNumber) => {
      let pageNo = (pageNumber !== void 0 && pageNumber !== null) ? pageNumber : 0;
      this.setState({
         currentPage: pageNo,
         pageList: [pageNo - 1, pageNo, pageNo + 1],
      });

   }

   setPageNumber = (pageName, pageNo) => {
      if (pageName) {
         this.setState({
            [pageName]: pageNo,
         });
      }
   }

   goNext = () => {
      if (this.state.nextPage) {
         this.loadData(this.state.nextPage);
      }
   }

   goPrev = () => {
      // console.log(this.state.prevPage);
      if (this.state.prevPage !== null && this.state.prevPage >= 0) {
         this.loadData(this.state.prevPage);
      }
   }

   setContainerHeight = (height) => {
      if (this.containerRef) {
         let currentHeight = this.containerRef.getBoundingClientRect().height;
         if (!currentHeight || height > currentHeight) {
            this.containerRef.style.height = height + 'px';
         }
      }
   }

   render() {
      const { currentPage } = this.state;
      return (
         <div className="project-list">
            <div className="pages-container" ref={this.setContainerRef}>
               {
                  this.state.pageList.map(pageNo => {
                     let pageName = (pageNo < currentPage) ? 'prevPage' : (pageNo > currentPage ? 'nextPage' : '');
                     return (
                        <ProjectPage
                           key={pageNo}
                           name={pageName}
                           category={this.props.category}
                           pageNo={pageNo}
                           pageSize={this.state.pageSize}
                           sort={this.props.sort || this.state.sort}
                           setPageNumber={this.setPageNumber}
                           setContainerHeight={this.setContainerHeight}
                        />
                     )
                  })
               }
            </div>
            {
               this.state.pageList.length !== 0 &&
               <div className="navigation-list">
                  <button className="wrapped-btn" onClick={this.goPrev} disabled={this.state.prevPage === null}>
                     <IconPrev />
                  </button>
                  <button className="wrapped-btn" onClick={this.goNext} disabled={!this.state.nextPage}>
                     <IconNext />
                  </button>
               </div>
            }
         </div>
      );
   }
}

export default ProjectList;