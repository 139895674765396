import Link from 'next/link';
import React, { PureComponent } from 'react';
import { projectApi } from '../../apis/ProjectApi';
import IconPrev from '../../assets/icons/IconPrev';
import DefaultBg from '../../assets/imgs/DefaultBg';
import { common } from '../../utils/common';
import { constants } from '../../utils/constants';
import { ReactComponent as IcLoading } from '../../assets/icons/ic-loading-1.svg';

class MbCatProjects extends PureComponent {
   state = {
      pageNo: 0,
      pageSize: 10,
      sort: 'updated_at:desc',
      data: [],
      finished: false,
      isLoading: true,
   }
   _mouted = false;
   abortController = null;


   componentWillUnmount() {
      this._mouted = false;
      if (this.abortController) {
         this.abortController.abort();
      }
   }

   componentDidMount() {
      this._mouted = true;
      this.abortController = new window.AbortController();
      this.loadData(this.state.pageNo, this.state.pageSize);

      if (this.containerRef) {
         this.containerRef.addEventListener('scroll', this.scrollToLoadContinues);
      }
   }

   scrollToLoadContinues = async () => {
      if (!this.state.finished && this.checkedRef) {
         let containerRefPosition = this.containerRef.getBoundingClientRect();
         let checkedRefPosition = this.checkedRef.getBoundingClientRect();
         if (checkedRefPosition.top <= containerRefPosition.bottom) {
            if (!this.state.isLoading) {
               await this.setState({ isLoading: true });
               setTimeout(async () => {
                  this.loadData(this.state.pageNo + 1, this.state.pageSize, true);
               }, 100);
            }
         }
      }
   }

   loadData = async (pageNo, pageSize, isLoadNext) => {
      const { catSlug } = this.props;
      const { sort } = this.state;

      const requestBody = {
         category: catSlug,
         start: pageNo * pageSize,
         limit: pageSize,
         sort: sort,
      };
      if (this._mouted) {
         const responseList = await projectApi.getProjectList(requestBody, this.abortController);
         let finished = true;
         if (responseList && this._mouted) {
            if (responseList.length >= pageSize) {
               finished = false;
            }
            let data = [];
            if (isLoadNext) {
               data = [...this.state.data, ...responseList];
            } else {
               data = responseList;
            }
            this.setState({
               data: data,
            });
         }
         this.setState({
            finished,
            isLoading: false,
         });
      }
   }

   setContainerRef = (r) => {
      this.containerRef = r;
   }
   setCheckedRef = (r) => {
      this.checkedRef = r;
   }

   render() {
      const category = this.props.catSlug;
      return (
         <div className="mb-page-cat-pro">
            <div className="sub-page-header">
               <Link href="/">
                  <a className="go-prev">
                     <IconPrev />
                  </a>
               </Link>
               <span>{this.props.catName}</span>
               <div className="blur-backdrop"></div>
            </div>
            <div className="sub-page-content" ref={this.setContainerRef}>
               <div className="cat-projects-list">
                  {
                     this.state.data.map(item => {
                        let thumbnailImg = common.getProjectThumbnail(item);
                        let projectUrl = `?${category ? `${constants.CATEGORY_QUERY}=${category}&` : ''}${constants.PROJECT_QUERY}=${item.slug}`;
                        return (
                           <div className="project-item-container" key={item.id}>
                              <Link href={projectUrl}>
                                 <a className="thumbnail-item animate__animated animate__zoomIn">
                                    <div className="background-thumbnail"
                                       style={{
                                          backgroundImage: thumbnailImg ? `url(${thumbnailImg})` : ''
                                       }}
                                    >
                                       {!thumbnailImg &&
                                          <div className="background-thumbnail-default">
                                             <DefaultBg />
                                          </div>
                                       }
                                    </div>
                                    <span className="pro-name">{item.name}</span>
                                 </a>
                              </Link>
                           </div>
                        )
                     })
                  }
                  {
                     (this.state.data.length >= this.state.pageSize && !this.state.finished) &&
                     <div ref={this.setCheckedRef}
                        style={{
                           height: '40px',
                           display: 'flex',
                           width: '100%',
                           alignItems: 'center',
                           justifyContent: 'center',
                           color: '#868686'
                        }}>
                        <IcLoading />
                     </div>
                  }
               </div>
            </div>
         </div>
      );
   }
}

export default MbCatProjects;