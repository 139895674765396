
import { constants } from "../utils/constants";
import ResponseModel from "./Models/ResponseModel";

const getToken = () => {
   if (localStorage[constants.TOKEN_VARIABLE]) {
      return `Bearer ${localStorage[constants.TOKEN_VARIABLE]}`;
   }
   return '';
}

export const serviceCommon = {
   getToken,
}