import React, { Fragment, PureComponent } from 'react';
import DefaultBg from '../../assets/imgs/DefaultBg';

class ImageItem extends PureComponent {
   viewFull = () => {
      if (this.props.img) {
         this.props.setPresentedImg(this.props.id);
      }
   }

   render() {
      const { img } = this.props;
      return (
         <div className={`thumbnail-item ${!img ? 'disabled' : ''}`} onClick={this.viewFull}>
            <div className="background-thumbnail animate__animated animate__zoomIn"
               style={{
                  backgroundImage: img ? `url(${img})` : ''
               }}
            >
               {!img &&
                  <DefaultBg />
               }
            </div>
         </div>
      );
   }
}

export default ImageItem;