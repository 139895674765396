import React, { Fragment, PureComponent } from 'react';
import Link from 'next/link';
import CategoryList from './CategoryList';
import ProjectDetail from './ProjectDetail';
import ProjectList from './ProjectList';
import BottomMenu from './BottomMenu';
import Footer from './Footer';
import { categoryApi } from '../apis/CategoryApi';
import { withRouter } from 'next/router';
import { constants } from '../utils/constants';
import MbSidebarMenu from './MbSidebarMenu';
import MbProjectList from './Mobile/MbProjectList';
import MbProjectDetail from './Mobile/MbProjectDetail';
import ChangeLanguage from './ChangeLanguage';
import { userApi } from '../apis/UserApi';
import { localeFunction } from '../locales/functions';

class Main extends PureComponent {
	state = {
		categories: [],
		category: '',
		project: '',
		loading: true,
		sort: 'updated_at:desc',
	};

	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.router !== this.props.router) {
			this.loadData();
		}
	}

	loadData = async () => {
		const { query } = this.props.router;
		const categories = await categoryApi.getCategoryList();
		let category =
			query[constants.CATEGORY_QUERY] &&
			categories.some((c) => c.slug === query[constants.CATEGORY_QUERY])
				? query[constants.CATEGORY_QUERY]
				: '';
		let project = query[constants.PROJECT_QUERY] ? query[constants.PROJECT_QUERY] : '';

		const setting = await userApi.getSetting();
		if (setting?.sort_by) {
			let [_by = 'updated_at', _type = 'desc'] = setting.sort_by.split(':');
			if (_by === 'ordinal') {
				_type = 'asc';
			}
			this.setState({
				sort: `${_by}:${_type}`,
			});
		}

		this.setState({
			categories,
			category,
			project,
			loading: false,
		});
	};

	renderMobileView = () => {
		const { categories, category, project, loading } = this.state;
		if (!loading) {
			if (project) {
				return (
					<MbProjectDetail
						isMobileView={this.props.isMobileView}
						category={category}
						projectSlug={project}
					/>
				);
			} else {
				return (
					<MbProjectList category={category} categories={categories} sort={this.state.sort} />
				);
			}
		}
		return null;
	};

	render() {
		const { categories, category, project, loading } = this.state;
		const { isMobileView } = this.props;

		return (
			<Fragment>
				<div className="main-header">
					{isMobileView && <MbSidebarMenu isMobileView={isMobileView} />}
					<Link href="/">
						<a className="header-logo">DNAP ARCHITECTURE</a>
					</Link>
					<ChangeLanguage />
				</div>
				{!isMobileView && (
					<CategoryList
						isMobileView={isMobileView}
						currentCat={category}
						categories={categories}
					/>
				)}
				{!isMobileView ? (
					<Fragment>
						{!loading && (
							<>
								{project ? (
									<ProjectDetail
										isMobileView={isMobileView}
										category={category}
										projectSlug={project}
									/>
								) : (
									<ProjectList
										isMobileView={isMobileView}
										category={category}
										sort={this.state.sort}
									/>
								)}
							</>
						)}
					</Fragment>
				) : (
					this.renderMobileView()
				)}
				{!isMobileView && <BottomMenu activeAll={!category} />}
				<Footer />
			</Fragment>
		);
	}
}

export default withRouter(Main);
