import { localeFunction } from "../locales/functions";

const TOKEN_VARIABLE = 'dnap-token-2021';

const CATEGORY_QUERY = 'cat';
const PROJECT_QUERY = 'pro';
const ADMIN_PAGE_QUERY = 'p';

const menuList = [
   {
      label: localeFunction.locale.about,
      route: '/about'
   },
   {
      label: localeFunction.locale.contact,
      route: '/contact'
   },
   {
      label: localeFunction.locale.awards,
      route: '/awards'
   },
   {
      label: localeFunction.locale.video,
      route: '/video'
   },
]

export const constants = {
   TOKEN_VARIABLE,

   CATEGORY_QUERY,
   PROJECT_QUERY,
   ADMIN_PAGE_QUERY,
   menuList,
}