import React, { PureComponent } from 'react';

class IconPrev2 extends PureComponent {
   render() {
      return (
         <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M16.9995 21.999L6.99951 11.999L16.9995 1.99902"
               stroke="currentColor"
               strokeWidth="1.5"
            />
         </svg>
      );
   }
}

export default IconPrev2;