import React, { memo, PureComponent } from 'react';
import { constants } from '../../utils/constants';
import Link from 'next/link';
import IconNext from '../../assets/icons/IconNext';
import { common } from '../../utils/common';
import DefaultBg from '../../assets/imgs/DefaultBg';
import MbCatProjects from './MbCatProjects';
import { localeFunction } from '../../locales/functions';

class MbProjectList extends PureComponent {
	render() {
		const { category, categories, sort } = this.props;
		const currentLocale = localeFunction.getCurrentLocale();

		if (!category) {
			return (
				<div className="mb-listed-category">
					{categories.map((cat) => {
						return (
							<div className="mb-cat-container" key={cat.slug}>
								<div className="container-header">
									<label>
										{cat[`name_${currentLocale}`]} ({cat.projectNumber || 0})
									</label>
									{cat.projectNumber > 5 && (
										<Link href={`/?${constants.CATEGORY_QUERY}=${cat.slug}`}>
											<a className="view-all">
												<span>{localeFunction.locale.view_all}</span>
												<IconNext />{' '}
											</a>
										</Link>
									)}
								</div>
								<div className="cat-pro-list">
									<CatProjectList
										category={cat.slug}
										projects={cat.projects}
										sort={sort}
									/>
								</div>
							</div>
						);
					})}
				</div>
			);
		} else {
			let currentCat = categories.find((c) => c.slug === category);
			return (
				<MbCatProjects
					catSlug={currentCat.slug}
					catName={currentCat[`name_${currentLocale}`]}
				/>
			);
		}
	}
}

const CatProjectList = memo(({ category, projects, sort }) => {
	let sortBy = 'updated_at';
	let sortType = 'desc';

	if (sort) {
		const sortParts = sort.split(':');
		if (sortParts?.[0]) sortBy = sortParts[0];
		if (sortParts?.[1]) sortType = sortParts[1];
	}
	const sortedProjects = _.orderBy(projects, [sortBy], [sortType]);
	const currentLocale = localeFunction.getCurrentLocale();

	return (
		<div className="view-pro-list-container">
			{sortedProjects.length === 0 && (
				<span className="no-projects">{localeFunction.locale.no_projects}</span>
			)}
			{sortedProjects.map((item) => {
				let thumbnailImg = common.getProjectThumbnail(item);
				let projectUrl = `?${category ? `${constants.CATEGORY_QUERY}=${category}&` : ''}${
					constants.PROJECT_QUERY
				}=${item.slug}`;

				return (
					<div className="project-item-container" key={item.id}>
						<Link href={projectUrl}>
							<a className="thumbnail-item animate__animated animate__zoomIn">
								<div
									className="background-thumbnail"
									style={{
										backgroundImage: thumbnailImg ? `url(${thumbnailImg})` : '',
									}}
								>
									{!thumbnailImg && (
										<div className="background-thumbnail-default">
											<DefaultBg />
										</div>
									)}
								</div>
								<span className="pro-name">{item[`name_${currentLocale}`]}</span>
							</a>
						</Link>
					</div>
				);
			})}
		</div>
	);
});

export default MbProjectList;
