import React, { Fragment, PureComponent } from 'react';
import _ from 'lodash';
import { projectApi } from '../../apis/ProjectApi';
import { withImagePresent } from '../ImagePresents/ImagePresent';
import { withRouter } from 'next/router';
import { apiConstants } from '../../apis/constants';
import ImageItem from '../ProjectList/ImageItem';
import { common } from '../../utils/common';
import ReactHtmlParser from 'react-html-parser';

class MbProjectDetail extends PureComponent {
   state = {
      data: null,
      allImages: [],
   }
   _mouted = false;
   abortController = null;

   componentWillUnmount() {
      this._mouted = false;
      if (this.abortController) {
         this.abortController.abort();
      }
   }

   componentDidMount() {
      this._mouted = true;
      this.abortController = new window.AbortController();
      this.loadData();
   }

   loadData = async () => {
      const { category, projectSlug } = this.props;
      const requestBody = {
         category: category,
         slug: projectSlug,
         start: 0,
         limit: 1,
      };
      if (this._mouted) {
         const responseList = await projectApi.getProjectList(requestBody, this.abortController);
         if (responseList && responseList.length !== 0) {
            let data = responseList[0];
            let allImages = data.images;
            if (allImages.length && data.ordinal_images && data.ordinal_images.length) {
               allImages = common.getSortedImageList(data.images, data.ordinal_images);
            } else {
               allImages = data.images ? _.orderBy(data.images, ['updated_at'], ['desc']) : [];
            }
            this.setState({
               data,
               allImages,
            });
         } else {
            this.props.router.push('/');
         }
      }
   }

   setPresentedImg = (imageId) => {
      let presentedImages = [];
      let presentedIndex = 0;
      this.state.allImages.forEach((imgItem, idx) => {
         if (imgItem.url) {
            presentedImages.push(`${apiConstants.API_CMS}${imgItem.url}`);
            if (imgItem.id === imageId) {
               presentedIndex = idx;
            }
         }
      });
      this.props.setImagePresents(presentedImages, presentedIndex);
   }

   render() {
      const { data, allImages } = this.state;
      return (
         <div className="mb-project-detail">
            {
               allImages.length !== 0 &&
               <div className="image-list-container">
                  <div className="overflow-list" ref={this.setContainerRef}>
                     {
                        allImages.map((item, idx) => {
                           let img = common.getImageThumbnail(item);
                           return (
                              <ImgItem
                                 key={item.id}
                                 index={idx}
                                 id={item.id}
                                 img={img}
                                 allImgNo={allImages.length}
                                 setPresentedImg={this.setPresentedImg}
                              />
                           )
                        })
                     }
                  </div>
               </div>
            }
            {
               data &&
               <Fragment>
                  <label className="pro-title animate__animated animate__fadeIn">{data.name}</label>
                  <div className="pro-desc">
                     <p className="description animate__animated animate__fadeIn">
                        {
                           data.description &&
                           <Fragment>
                              {ReactHtmlParser(data.description)}
                           </Fragment>
                        }
                     </p>
                  </div>
               </Fragment>
            }
         </div>
      );
   }
}


class ImgItem extends PureComponent {

   setPresentedImg = () => {
      this.props.setPresentedImg(this.props.id);
   }

   render() {
      const { id, index, img, allImgNo } = this.props;
      return (
         <div className={`thumbnail-item`} onClick={this.setPresentedImg}>
            <div className="background-thumbnail animate__animated animate__zoomIn">
               <img src={img} />
            </div>
            <div className={`info `}>{index + 1}/{allImgNo}</div>
         </div>
      );
   }
}


export default withRouter(withImagePresent(MbProjectDetail));