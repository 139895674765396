import React, { PureComponent } from 'react';
import { apiConstants } from '../../apis/constants';
import { projectApi } from '../../apis/ProjectApi';
import { constants } from '../../utils/constants';
import Link from 'next/link';
import DefaultBg from '../../assets/imgs/DefaultBg';
import { common } from '../../utils/common';
import { localeFunction } from '../../locales/functions';

class ProjectPage extends PureComponent {
   state = {
      data: [],
      currentLocale: localeFunction.getCurrentLocale()
   }
   _mouted = false;
   pageRef = null;
   abortController = null;

   setPageRef = (r) => {
      this.pageRef = r;
   }

   componentWillUnmount() {
      this._mouted = false;
      if (this.abortController) {
         this.abortController.abort();
      }
   }

   componentDidMount() {
      this._mouted = true;
      this.abortController = new window.AbortController();
      this.loadData();
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.name !== this.props.name || prevProps.category !== this.props.category || prevProps.sort !== this.props.sort) {
         this.loadData();
      }
   }

   setContainerHeight = () => {
      if (!this.props.name && this.pageRef) {
         this.props.setContainerHeight(this.pageRef.getBoundingClientRect().height);
      }
   }

   loadData = async () => {
      const { category, pageNo, pageSize, sort } = this.props;
      if (pageNo === null || pageNo === void 0 || pageNo < 0) {
         if (this.props.setPageNumber) {
            this.props.setPageNumber(this.props.name, null);
         }
         return;
      }
      const requestBody = {
         category: category,
         start: pageNo * pageSize,
         limit: pageSize,
         sort: sort,
      };
      if (this._mouted) {
         const responseList = await projectApi.getProjectList(requestBody, this.abortController);
         if (responseList && this._mouted) {
            await this.setState({
               data: responseList.map(data => ({
                  ...data,
                  name: data[`name_${this.state.currentLocale}`],
				      description: data[`description_${this.state.currentLocale}`] || '',
               })),
            });
            this.setContainerHeight();
            if (this.props.setPageNumber) {
               if (responseList.length !== 0) {
                  this.props.setPageNumber(this.props.name, pageNo);
               } else {
                  this.props.setPageNumber(this.props.name, null);
               }
            }
         } else {
            if (this.props.setPageNumber) {
               this.props.setPageNumber(this.props.name, null);
            }
         }
      }
   }

   render() {
      if (this.state.data.length !== 0) {
         return (
            <div className={`outline-box  ${this.props.name}`} ref={this.setPageRef}>
               <div className={`thumbnail-container`}>
                  {
                     this.state.data.map((item) => {
                        let category = this.props.category || '';
                        let thumbnailImg = common.getProjectThumbnail(item);
                        let projectUrl = `?${category ? `${constants.CATEGORY_QUERY}=${category}&` : ''}${constants.PROJECT_QUERY}=${item.slug}`;
                        return (
                           <Link href={projectUrl} key={item.id}>
                              <a className="thumbnail-item animate__animated animate__zoomIn">
                                 <div className="background-thumbnail"
                                    style={{
                                       backgroundImage: thumbnailImg ? `url(${thumbnailImg})` : ''
                                    }}
                                 >
                                    {!thumbnailImg &&
                                       <DefaultBg />
                                    }
                                 </div>
                                 <div className="img-cover">
                                    <span>{item.name}</span>
                                 </div>
                              </a>
                           </Link>
                        )
                     })
                  }
                  {
                     (this.state.data.length < this.props.pageSize) &&
                     Array.from({ length: this.props.pageSize - this.state.data.length }, (x, i) => i).map((fakeItem) => (
                        <div className="thumbnail-item blank" key={fakeItem} />
                     ))
                  }
               </div>
            </div>
         );
      }
      return null;
   }
}

export default ProjectPage;