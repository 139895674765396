import words_en from './en.json';
import words_vi from './vi.json';
import Cookies from 'js-cookie';

const VIETNAMESE = 'vi';
const ENGLISH = 'en';
const LANGUAGE = 'lang';

const getLocaleMessage = (lang) => {
	try {
		let currentLocale = Cookies.get(LANGUAGE);
		const messages = {
			en: words_en,
			vi: words_vi,
		};
		let language = currentLocale || lang;
		let output = messages[language];
		return output;
	} catch (error) {
		return messages[ENGLISH];
	}
};

const getCurrentLocale = () => {
	const currentLocale = Cookies.get(LANGUAGE);
	if (!currentLocale) {
		Cookies.set(LANGUAGE, ENGLISH);
		return ENGLISH;
	}

	return currentLocale;
};

const locale = getLocaleMessage(ENGLISH);

export const localeFunction = {
	LANGUAGE,
	VIETNAMESE,
	ENGLISH,

	getLocaleMessage,
	getCurrentLocale,
	locale,
};
