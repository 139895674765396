import React, { Fragment, PureComponent } from 'react';
import { projectApi } from '../apis/ProjectApi';
import { withRouter } from 'next/router';
import ProjectImagesPage from './ProjectList/ProjectImagesPage';
import _ from 'lodash';
import IconPrev from '../assets/icons/IconPrev';
import IconNext from '../assets/icons/IconNext';
import { ViewImageContext } from '../context/ViewImageContext';
import { withImagePresent } from './ImagePresents/ImagePresent';
import { apiConstants } from '../apis/constants';
import ReactHtmlParser from 'react-html-parser';
import { common } from '../utils/common';
import { localeFunction } from '../locales/functions';

class ProjectDetail extends PureComponent {
   state = {
      data: null,

      allImages: [],
      img_pageSize: 9,
      img_pageList: [],
      img_currentPage: 0,
      currentLocale: localeFunction.getCurrentLocale()
   }
   _mouted = false;
   abortController = null;

   componentWillUnmount() {
      this._mouted = false;
      if (this.abortController) {
         this.abortController.abort();
      }
   }

   componentDidMount() {
      this._mouted = true;
      this.abortController = new window.AbortController();
      this.loadData();
   }

   loadData = async () => {
      const { category, projectSlug } = this.props;
      const requestBody = {
         category: category,
         slug: projectSlug,
         start: 0,
         limit: 1,
      };
      if (this._mouted) {
         const responseList = await projectApi.getProjectList(requestBody, this.abortController);
         console.log("🚀 ~ ProjectDetail ~ loadData= ~ responseList:", responseList)
         if (responseList && responseList.length !== 0) {
            let data = {
               ...responseList[0],
               name: responseList[0][`name_${this.state.currentLocale}`],
               description: responseList[0][`description_${this.state.currentLocale}`] || '',
            };
            let allImages = data.images;
            if (allImages.length && data.ordinal_images && data.ordinal_images.length) {
               allImages = common.getSortedImageList(data.images, data.ordinal_images);
            } else {
               allImages = data.images ? _.orderBy(data.images, ['updated_at'], ['desc']) : [];
            }
            this.loadImgsPageList(0, data.images ? data.images.length || 0 : 0);
            this.setState({
               data,
               allImages,
            });
         } else {
            this.props.router.push('/');
         }
      }
   }

   loadImgsPageList = async (pageNumber, totalImgs) => {
      let pageNo = (pageNumber !== void 0 && pageNumber !== null) ? pageNumber : 0;
      let pageList = [];
      let totalImgNumber = totalImgs !== void 0 ? totalImgs : this.state.allImages.length;
      if (pageNo > 0) {
         pageList.push(pageNo - 1);
      }
      pageList.push(pageNo);
      if (totalImgNumber > (pageNo + 1) * this.state.img_pageSize) {
         pageList.push(pageNo + 1);
      }
      this.setState({
         img_currentPage: pageNo,
         img_pageList: pageList,
      });

   }

   setContainerHeight = (height) => {
      if (this.imgContainerRef) {
         let currentHeight = this.imgContainerRef.getBoundingClientRect().height;
         if (!currentHeight || height > currentHeight) {
            this.imgContainerRef.style.height = height + 'px';
         }
      }
   }

   setImageContainerRef = (r) => {
      this.imgContainerRef = r;
   }

   goNext = () => {
      this.loadImgsPageList(this.state.img_currentPage + 1);
   }

   goPrev = () => {
      this.loadImgsPageList(this.state.img_currentPage - 1);
   }

   setPresentedImg = (imageId) => {
      let presentedImages = [];
      let presentedIndex = 0;
      this.state.allImages.forEach((imgItem, idx) => {
         if (imgItem.url) {
            presentedImages.push(`${apiConstants.API_CMS}${imgItem.url}`);
            if (imgItem.id === imageId) {
               presentedIndex = idx;
            }
         }
      });
      this.props.setImagePresents(presentedImages, presentedIndex);
   }

   render() {
      // console.log(this.state.data);
      const { data, allImages, img_currentPage, img_pageSize, img_pageList } = this.state;
      if (data) {
         return (
            <div className="project-detail-container">
               <div className="detail-grids">
                  <div className="pro-info">
                     <div className="desc-frame">
                        <p className="description animate__animated animate__fadeIn">
                           {
                              data.description &&
                              <Fragment>
                                 {ReactHtmlParser(data.description)}
                              </Fragment>
                           }
                        </p>
                     </div>
                     <label className="animate__animated animate__fadeIn">{data.name}</label>
                  </div>
                  <div className="pages-container image-list-container" ref={this.setImageContainerRef}>
                     {
                        img_pageList.map(pageNo => {
                           let pageName = (pageNo < img_currentPage) ? 'prevPage' : (pageNo > img_currentPage ? 'nextPage' : '');
                           return (
                              <ProjectImagesPage
                                 key={pageNo}
                                 name={pageName}
                                 pageNo={pageNo}
                                 pageSize={img_pageSize}
                                 allImages={allImages}
                                 setContainerHeight={this.setContainerHeight}
                                 setPresentedImg={this.setPresentedImg}
                              />
                           )
                        })
                     }
                  </div>
                  <div></div>
                  {
                     img_pageList.length !== 0 &&
                     <div className="navigation-list">
                        <button className="wrapped-btn" onClick={this.goPrev} disabled={img_pageList[0] === img_currentPage}>
                           <IconPrev />
                        </button>
                        <button className="wrapped-btn" onClick={this.goNext} disabled={img_pageList[img_pageList.length - 1] === img_currentPage}>
                           <IconNext />
                        </button>
                     </div>
                  }
               </div>
            </div>
         );
      }
      return null;
   }
}

export default withRouter(withImagePresent(ProjectDetail));