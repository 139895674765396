import { serviceCommon } from "./common";
import { apiConstants } from "./constants";

const getMe = async (abortController) => {
   let restObj = {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
         'Authorization': serviceCommon.getToken(),
      },
   }
   if (abortController) {
      restObj.signal = abortController.signal;
   }
   let url = apiConstants.GET_ME;

   try {
      let response = await fetch(url, restObj);
      let body = await response.json();
      // console.log(body);
      if (body && body.id) {
         return body;
      }
      return null;
   }
   catch (e) {
      return null;
   }
}

const getSetting = async (abortController) => {
   let restObj = {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   }
   if (abortController) {
      restObj.signal = abortController.signal;
   }
   let url = apiConstants.GET_SETTING;

   try {
      let response = await fetch(url, restObj);
      let body = await response.json();
      return body;
   }
   catch (e) {
      return null;
   }
}

export const userApi = {
   getMe,
   getSetting,
}