import React, { Fragment, PureComponent } from 'react';
import { apiConstants } from '../../apis/constants';
import { ViewImageContext } from '../../context/ViewImageContext';
import ImageItem from './ImageItem';

class ProjectImagesPage extends PureComponent {
   state = {
      data: [],
   }
   pageRef = null;

   setPageRef = (r) => {
      this.pageRef = r;
   }

   componentDidMount() {
      this.loadData();
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.name !== this.props.name) {
         this.loadData();
      }
   }

   setContainerHeight = () => {
      if (!this.props.name && this.pageRef) {
         this.props.setContainerHeight(this.pageRef.getBoundingClientRect().height);
      }
   }

   loadData = async () => {
      const { pageNo, pageSize, allImages } = this.props;
      if (pageNo === null || pageNo === void 0 || pageNo < 0) {
         return;
      }
      let data = allImages.slice(pageNo * pageSize, pageNo * pageSize + pageSize);
      await this.setState({
         data,
      });
      this.setContainerHeight();
   }


   getThumbnailImg = (item) => {
      if (item.formats && item.formats.small) {
         return `${apiConstants.API_CMS}${item.formats.small.url}`;
      } else if (item.url) {
         return `${apiConstants.API_CMS}${item.url}`;
      }
      return '';
   }

   render() {
      return (
         <div className={`outline-box  ${this.props.name}`} ref={this.setPageRef}>
            <div className="thumbnail-container">
               <Fragment>
                  {
                     this.state.data.map((item) => {
                        return (
                           <ImageItem key={item.id}
                              id={item.id}
                              img={this.getThumbnailImg(item)}
                              setPresentedImg={this.props.setPresentedImg}
                           />
                        )
                     })
                  }
               </Fragment>
               {
                  (this.state.data.length < this.props.pageSize) &&
                  Array.from({ length: this.props.pageSize - this.state.data.length }, (x, i) => i).map((fakeItem) => (
                     <div className="thumbnail-item blank" key={fakeItem} />
                  ))
               }
            </div>
         </div>
      );
   }
}

export default ProjectImagesPage;