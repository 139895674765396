import { serviceCommon } from "./common";
import { apiConstants } from "./constants";

const getProjectList = async (requestBody, abortController) => {
   if (!requestBody) {
      return;
   }
   let restObj = {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   }
   if (abortController) {
      restObj.signal = abortController.signal;
   }

   let query = (requestBody.category ? `categories.slug=${requestBody.category}&` : '')
      + (requestBody.slug ? `slug=${requestBody.slug}&` : '')
      + (requestBody.limit !== void 0 ? `_limit=${requestBody.limit}&` : '')
      + (requestBody.start !== void 0 ? `_start=${requestBody.start}&` : '')
      + (requestBody.sort ? `_sort=${requestBody.sort}` : '')
      ;
   let url = apiConstants.GET_PROJECTS(query);

   try {
      let response = await fetch(url, restObj);
      let body = await response.json();
      return body;
   }
   catch (e) {
      return [];
   }
}

export const projectApi = {
   getProjectList,
}