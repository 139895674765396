import { categoryApi } from '../apis/CategoryApi';
import Layout from '../components/Layout';
import Main from '../components/Main';
import { constants } from '../utils/constants';

const Home = (props) => {

	return (
		<Layout
			{...props}
			WrappedComponent={Main}
		/>
	)
}

export async function getServerSideProps({ req }) {
	let isMobileView = (req
		? req.headers['user-agent']
		: navigator.userAgent).match(
			/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
		);

	return {
		props: {
			isMobileView: Boolean(isMobileView),
		}
	}
}

export default Home;
