import React, { Fragment, memo, PureComponent } from 'react';
import IconClose from '../../assets/icons/IconClose';
import IconNext2 from '../../assets/icons/IconNext2';
import IconPrev2 from '../../assets/icons/IconPrev2';
import { ViewImageContext } from '../../context/ViewImageContext';

const bodyModalClassname = 'modal-open';

export const withImagePresent = (WrappedComponent) => {
   return class ImagePresent extends PureComponent {
      state = {
         images: [],
         currentIndex: 0,

         openPresent: false,
      }
      containerRef = null;

      setContainerRef = (r) => {
         this.containerRef = r;
      }

      componentWillUnmount() {
         this.removeModalClass();
      }


      setImagePresents = (images = [], index) => {
         // console.log(index);
         // console.log(images);
         this.setState({
            images: images,
            currentIndex: images[index] ? index : 0,
            openPresent: true,
         }, () => {
            setTimeout(() => {
               if (this.containerRef) {
                  this.containerRef.focus();
               }
            }, 300);
         });
         this.addModalClass();
      }

      removeModalClass = () => {
         if (document.body.classList.contains(bodyModalClassname)) {
            const pattern = new RegExp('(?:^|\\s)' + bodyModalClassname + '(?:\\s|$)', 'g');
            document.body.className = document.body.className.replace(pattern, '');
         }
      }

      addModalClass = () => {
         if (!document.body.classList.contains(bodyModalClassname)) {
            document.body.classList.add(bodyModalClassname);
         }
      }

      closePresent = () => {
         this.setState({
            openPresent: false,
         });
         this.removeModalClass();
      }

      preventClose = (e) => {
         e.stopPropagation();
      }

      goNext = () => {
         let newIndex = this.state.currentIndex + 1;
         if (newIndex > this.state.images.length - 1) {
            newIndex = 0;
         }
         this.setState({
            currentIndex: newIndex,
         });
      }
      goPrev = () => {
         let newIndex = this.state.currentIndex - 1;
         if (newIndex < 0) {
            newIndex = this.state.images.length - 1;
         }
         this.setState({
            currentIndex: newIndex,
         });
      }

      onKeyPress = (e) => {
         if (e.key === "Escape") {
            this.closePresent();
         }
         else if (e.keyCode == 37) {
            this.goPrev();
         }
         else if (e.keyCode == 39) {
            this.goNext();
         }
      }

      render() {
         const { isMobileView } = this.props;
         const { images, currentIndex, openPresent } = this.state;
         let pageList = [];
         let prevIndex = currentIndex - 1;
         let nextIndex = currentIndex + 1;
         let canNavigate = true;
         if (openPresent) {
            if (images.length <= 1) {
               canNavigate = false;
            }
            if (canNavigate) {
               if (prevIndex < 0) {
                  prevIndex = images.length - 1;
               }
               if (nextIndex > images.length - 1) {
                  nextIndex = 0;
               }
            } else {
               pageList = [currentIndex];
            }

            pageList = [prevIndex, currentIndex, nextIndex];
         }
         return (
            <ViewImageContext.Provider value={{
               setImagePresents: this.setImagePresents,
            }}>
               <WrappedComponent
                  {...this.props}
                  setImagePresents={this.setImagePresents}
               />
               <div className={`presented-container animate__animated ${openPresent ? 'open animate__fadeIn' : ''}`}
                  tabIndex="1"
                  ref={this.setContainerRef}
                  onKeyDown={this.onKeyPress}
               >
                  {
                     !isMobileView ?
                        <Fragment>
                           <button className="close-btn" onClick={this.closePresent}>
                              <IconClose />
                           </button>
                           {
                              canNavigate &&
                              <div className="go-prev" onClick={this.goPrev}>
                                 <span>
                                    <IconPrev2 />
                                 </span>
                              </div>
                           }
                           {
                              canNavigate &&
                              <div className="go-next" onClick={this.goNext}>
                                 <span>
                                    <IconNext2 />
                                 </span>
                              </div>
                           }
                           <div className="presented-frame" onClick={this.closePresent}>
                              {
                                 pageList.map((imgIndex) => {
                                    let status = (imgIndex === prevIndex) ? 'prev' : (imgIndex === nextIndex ? 'next' : '');
                                    return (
                                       <div className={`img-content ${status}`} key={imgIndex}>
                                          <img src={images[imgIndex]} onClick={this.preventClose} />
                                       </div>
                                    )
                                 })
                              }
                           </div>
                        </Fragment>
                        :
                        <Fragment>
                           <button className="close-btn" onClick={this.closePresent}>
                              <IconClose />
                           </button>
                           <div className="presented-frame">
                              <div className="overflow-list">
                                 {
                                    images.map((img, index) => {
                                       return (
                                          <ImgItem
                                             key={index}
                                             img={img}
                                             index={index}
                                             length={images.length}
                                             isCurrent={currentIndex === index}
                                             closePresent={this.closePresent}
                                          />
                                       )
                                    })
                                 }
                              </div>
                           </div>
                        </Fragment>
                  }
               </div>
            </ViewImageContext.Provider>
         );
      }
   }
}
class ImgItem extends PureComponent {

   componentDidMount() {
      this.scroll();
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.isCurrent !== this.props.isCurrent) {
         this.scroll();
      }
   }

   scroll = () => {
      setTimeout(() => {
         if (this.props.isCurrent) {
            this.cRef.scrollIntoView({ block: "start" });
         }
      }, 300);
   }


   setInnerRef = (r) => {
      this.cRef = r;
   }

   preventClose = (e) => {
      e.stopPropagation();
   }

   render() {
      const { img, index, length } = this.props;
      return (
         <div className={`img-content-container`} ref={this.setInnerRef} onClick={this.props.closePresent}>
            <div className="img-content">
               <img src={img} onClick={this.preventClose} />
            </div>
            <div className={`info`}>{index + 1}/{length}</div>
         </div>
      );
   }
}
